/*
import { fetchTicket } from '@/api/common';
 * @Description:
 * @Author: laicheng
 * @Date: 2022-06-23 17:58:25
 */
import { fetchTicket } from '@/API/common'
import wechat from 'weixin-js-sdk'
export async function authTag(url) {
  const result = await fetchTicket({ url: location.href + '/' })
  wechat.config({
    debug: false,
    appId: result.appId,
    timestamp: result.timestamp,
    nonceStr: result.nonceStr,
    signature: result.signature,
    openTagList: [
      'wx-open-launch-weapp'
    ]
  })
}
